import Vue from 'vue';
import Print from 'vue-print-nb';
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import setupFirebase from './firebaseSetup';

Vue.use(setupFirebase);
Vue.use(Print);
Vue.use(VueTelInputVuetify, {
  vuetify,
});
Vue.config.productionTip = false;
Vue.prototype.firebaseInit();

let vm;
Vue.prototype.firebase.auth().onAuthStateChanged(async (user) => {
  // console.log(user);
  if (user) {
    store.dispatch('user/signIn', {
      uid: user.uid,
      name: user.displayName,
      email: user.email,
      refreshToken: user.refreshToken,
      emailVerified: user.emailVerified,
      metadata: user.metadata,
      phone: user.phoneNumber,
    });
    if (router.currentRoute.matched.length > 0) {
      router.push('/app');
    }
  }
  if (!vm) {
    vm = new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
