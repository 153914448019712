const superAdmin = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    to: '/app/dashboard',
  },
  {
    title: 'Create HR',
    icon: 'mdi-account-multiple-plus-outline',
    to: '/app/adminModule/create-hr',
  },
  {
    title: 'Manage HR',
    icon: 'mdi-content-save-edit-outline',
    to: '/app/adminModule/manage-hr',
  },
  {
    title: 'View Logs',
    icon: 'mdi-archive-eye-outline',
    to: '/app/adminModule/view-logs',
  },
];

const admin = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    to: '/app/dashboard',
  },
  {
    title: 'Create Applicant',
    icon: 'mdi-account-multiple-plus-outline',
    to: '/app/hrModule/add-applicant',
  },
  {
    title: 'Manage',
    icon: 'mdi-semantic-web',
    children: [
      {
        title: 'Applicants',
        icon: 'mdi-content-save-edit-outline',
        to: '/app/hrModule/manage-applicants',
      },
      {
        title: 'Submitted-forms',
        icon: 'mdi-content-save-edit-outline',
        to: '/app/hrModule/manage-applications',
      },
    ],
  },
];

const nonAdmin = [
  {
    title: 'Dashboard',
    icon: 'mdi-view-dashboard',
    to: '/app/dashboard',
  },
  {
    title: 'Pre-Recruitment form',
    icon: 'mdi-form-select',
    to: '/app/praForm/apply-form',
  },
];

export default (type) => {
  let menu = '';
  if (type === 'superAdmin') {
    menu = superAdmin;
  } else if (type === 'Admin') {
    menu = admin;
  } else {
    menu = nonAdmin;
  }
  return menu;
};
