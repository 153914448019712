<template>
  <v-app-bar app clipped-left color="primary">
    <v-app-bar-nav-icon
      color="secondary"
      @click.stop="$store.dispatch('drawerAction', !$store.state.drawer)">
    </v-app-bar-nav-icon>
    <v-img src="../assets/manipalColorfulLogo.png" max-height="50px" max-width="50px"></v-img>
    <label style="color:white; margin:10px">Recruitment Applicant Portal</label>
    <v-spacer></v-spacer>
    <v-toolbar-title class="mr-12 align-center">
      <v-icon>mdi-Logout</v-icon>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-avatar v-on="on" size="36" color="secondary">
          <v-icon >mdi-logout</v-icon>
        </v-avatar>
      </template>

      <v-list>
        <v-list-item link @click.prevent="signOut">
          <v-list-item-title>Sign Out</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('user', [
      'signOut',
    ]),
  },
  created() {
    // console.log(this);
  },
};
</script>
