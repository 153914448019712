<template>
<v-main>
  <v-container>
    <center>
      <v-card max-width="555">
        <div style="height:320px;width:211px;">
          <v-img
          src="../assets/manipalColourfulLongLogo.png"
          :contain="true"
        ></v-img>
        </div>
        <div style="display: flex; justify-content: center;">
        <v-icon color="black">mdi-human-greeting-proximity</v-icon>
        <v-card-title>Recruitment Applicant Portal</v-card-title>
        </div>
        <v-card-text>
          <v-snackbar
        v-model="error.status"
        relative
        color="error"
        bottom
      >
        {{error.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar
        v-model="success.status"
        relative
        color="success"
        bottom
      >
        {{success.message}}
        <template v-slot:action="{ attrs }">
          <v-btn
          color="black"
            text-color="error"
            v-bind="attrs"
            @click="error.status = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
          <v-form
            class="text-center"
            ref="loginForm"
            @submit.prevent="login"
          >
            <v-text-field
              label="Username/Email to login"
              color="primary"
              :rules="usernameRules"
              prepend-inner-icon="mdi-account-circle-outline"
              v-model="auth.email"
              required
            ></v-text-field>
            <v-text-field
              label="Password"
              color="primary"
              type="password"
              :rules="passwordRules"
              prepend-inner-icon="mdi-lock-check-outline"
              v-model="auth.password"
              required
            ></v-text-field>
            <v-btn
              type="submit"
              color="primary"
              :loading="proccessing"
            >Sign In</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </center>
  </v-container>
  </v-main>
</template>

<script>
export default {
  data() {
    return {
      auth: {
        email: '',
        password: '',
      },
      usernameRules: [
        (v) => !!v || 'Email is required',
        (v) => (v && v.length > 3) || 'Email must be minimum 3 characters',
        (v) => !(/[ ]/.test(v)) || 'No blank spaces allowed',
      ],
      passwordRules: [
        (v) => !!v || 'Password is required',
        (v) => !(/[ ]/.test(v)) || 'No blank spaces allowed',
        (v) => (v && v.length > 2) || 'Password is not valid',
      ],
      success: {
        status: false,
        message: '',
      },
      error: {
        status: false,
        message: '',
      },
      proccessing: false,
    };
  },
  methods: {
    async login() {
      this.error.status = false;
      this.success.false = false;
      if (this.$refs.loginForm.validate()) {
        this.proccessing = true;
        try {
          await this.firebase.auth().signInWithEmailAndPassword(
            this.auth.email,
            this.auth.password,
          );
          this.proccessing = false;
          // console.log(user);
        } catch (error) {
          this.proccessing = false;
          console.log(error);
          this.error.status = true;
          this.error.message = 'Unknown Error! Please try again';
          if (error.code === 'auth/user-not-found') {
            this.error.message = 'User Not Found';
          }
        }
      }
    },
  },
};
</script>
