const config = {
  apiKey: 'AIzaSyBz8eIc5S07zxPzxIiQt6kSWA2maEhpH8M',
  authDomain: 'manipal-pra-app.firebaseapp.com',
  projectId: 'manipal-pra-app',
  storageBucket: 'manipal-pra-app.appspot.com',
  messagingSenderId: '803457815118',
  appId: '1:803457815118:web:46e3d5910fc31262efe91f'

};

export default config;
