import Home from '../views/Home.vue';
import checkAuth from '../utils/checkAuth';

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    beforeEnter: checkAuth,
  },
  {
    path: '/app',
    name: 'app',
    component: () => import('../views/app'),
    redirect: '/app/dashboard',
    beforeEnter: checkAuth,
    meta: {
      authRequired: true,
    },
    children: [
      {
        path: 'dashboard',
        name: 'app-dashboard',
        component: () => import('../views/app/home'),
      },
      {
        path: 'hrModule',
        name: 'hrModule',
        component: () => import(/* webpackChunkName: "HR module" */ '../views/app/hrModule'),
        children: [
          {
            path: 'add-applicant',
            component: () => import(/* webpackChunkName: "app-hrModlue-add-user" */ '../views/app/hrModule/create.vue'),
          },
          {
            path: 'manage-applications',
            component: () => import(/* webpackChunkName: "app-hrModlue-manage-applications" */ '../views/app/hrModule/manage.vue'),
          },
          {
            path: 'manage-applicants',
            component: () => import(/* webpackChunkName: "app-hrModlue-manage-user" */ '../views/app/hrModule/manageUsers.vue'),
          },
          {
            name: 'display-form',
            path: 'display-form',
            props: true,
            component: () => import(/* webpackChunkName: "app-hrModlue-manage-user" */ '../views/app/hrModule/displayform.vue'),
          },
        ],
      },
      {
        path: 'adminModule',
        name: 'adminModule',
        component: () => import(/* webpackChunkName: "HR module" */ '../views/app/adminModule'),
        children: [
          {
            path: 'create-hr',
            component: () => import(/* webpackChunkName: "app-hrModlue-add-user" */ '../views/app/adminModule/createHR.vue'),
          },
          {
            path: 'manage-hr',
            component: () => import(/* webpackChunkName: "app-hrModlue-manage-applications" */ '../views/app/adminModule/manageHR.vue'),
          },
          {
            path: 'view-logs',
            component: () => import(/* webpackChunkName: "app-hrModlue-manage-applications" */ '../views/app/adminModule/actionLog.vue'),
          },
        ],
      },
      {
        path: 'praForm',
        name: 'praForm',
        component: () => import(/* webpackChunkName: "HR module" */ '../views/app/praForm'),
        children: [
          {
            path: 'apply-form',
            component: () => import(/* webpackChunkName: "app-hrModlue-add-user" */ '../views/app/praForm/mainForm.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "signup" */ '../views/Login.vue'),
  },
  {
    path: '*',
    name: 'not-found-error',
    component: () => import(/* webpackChunkName: "not-found-error" */ '../components/NotFound.vue'),
  },
];

export default routes;
